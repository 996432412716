import {endpoint, fetch_options} from "./helper";

const url = `${endpoint}/tariffs-gb`;
export async function getTariffGbListAPI(){
    return fetch(`${url}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export async function createTariffGbAPI(data:any){
    const body = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateTariffGbAPI(tariffGbId:string,data:any){
    const body = JSON.stringify(data);
    return fetch(`${url}/${tariffGbId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteTariffGbAPI(tariffGbId:string){
    return fetch(`${url}/${tariffGbId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}