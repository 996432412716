import {endpoint} from "./index";
import {fetch_options} from "./header";

export function getCouponsAPI() {
    return fetch(endpoint+'/coupons',fetch_options).then(r=>r.json());
}
export function addCouponAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/coupons`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateCouponAPI(id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/coupons/${id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteCouponAPI(id){
    return fetch(`${endpoint}/coupons/${id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}