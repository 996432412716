const activeApplySettingsLogReducer = (state = [], action:any) => {
    const duplicate = state.some((row:any) => (row.imei === action.data.imei));
    switch (action.type) {
        case "ADD_ACTIVE_APPLY_SETTINGS_LOG":
        case "UPDATE_ACTIVE_APPLY_SETTINGS_LOG":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c.imei !== action.data.imei){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_ACTIVE_APPLY_SETTINGS_LOG":
            return state.filter(
                (c:any) => c.imei !== action.data.imei
            )
        case "LOAD_ACTIVE_APPLY_SETTINGS_LOG":
            return action.data;
        default:
            return state;
    }


}
export const loadActiveApplySettingsLog = (data:any) => ({type: "LOAD_ACTIVE_APPLY_SETTINGS_LOG", data})
export const updateActiveApplySettingsLog = (data:any) => ({type: "UPDATE_ACTIVE_APPLY_SETTINGS_LOG", data});
export default activeApplySettingsLogReducer;