import {EditableCell} from "../../Libs/ReactTable/editable-cell";
import {SwitchCell} from "../../Libs/ReactTable/switch-cell";
import {Cell} from "@tanstack/react-table";

export default function backendPortsColumns(props:any){
    return [
        {
            header:"Country",
            accessorKey: 'country',
            disableFilters:true,
            size:200,
        },
        {
            header:"ID",
            accessorKey: 'id',
            disableFilters:true,
            size:100,
        },
        {
            header:"Connection IP",
            accessorKey: 'connectionIp',
            disableFilters:true,
            size:100,
        },
        {
            header:"HTTP Port",
            accessorKey: 'httpPort',
            disableFilters:true,
            size:100,
        },
        {
            header:"SOCKS5 Port",
            accessorKey: 'socks5Port',
            disableFilters:true,
            size:100,
        },
        {
            header:"Username",
            accessorKey: 'username',
            disableFilters:true,
            size:100,
        },
        {
            header:"Password",
            accessorKey: 'password',
            disableFilters:true,
            size:100,
        },
        {
            header:"Front-End Port",
            accessorKey: 'frontEndPort',
            disableFilters:true,
            size:100,
        },
        {
            header:"Status",
            accessorKey: 'status',
            disableFilters:true,
            size:100,
        },
        {
            header:"GB Used",
            accessorKey: 'gbUsed',
            disableFilters:true,
            size:100,
        },
        {
            header:"Live",
            id:'live',
            accessorFn: (row:any)=>row,
            disableFilters:true,
            size:100,
            cell:SwitchCell
        },
        {
            header:"Admin Actions",
            id:'action',
            accessorFn: (row:any)=>row,
            disableFilters:true,
            size:100,
            cell:(info:Cell<any, any>)=>{
                const {_id} = info.getValue();
                return <span>Run Test</span>
            }
        },
    ]
}