import "isomorphic-fetch";
export * from "./language-api"
export * from "./coupon-api"
export * from "./thirdpart-api"
export * from "./farmers-api"
export * from "./country-api"
export * from "./tariff-api"
export * from "./modem-api"
export * from "./server-api"
export * from "./user-api"
export * from "./auth-api"
export * from "./setting-api"
export * from "./account-api"
export * from "./mail-api"
export * from "./tariffs-gb-api"
export * from "./telegram-api"


export const endpoint = window.location.origin+"/api/v1";
const fetch_options = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
};


export function getCarriersAPI() {
  return fetch(endpoint+'/carriers',fetch_options).then(r=>r.json());
}

export function addManualInvoiceAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/add-manual-invoice`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function withdrawAPI(coin='btc',data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/withdraw/${coin}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
