const server_events =  (state={}, action) => {
    switch (action.type) {
        case "ADD_SERVER_EVENT":
            return {
                ...action.data
            };
        default :
            return state
    }
}
export default server_events;

export const addServerEvent = (event,msg) =>({type:'ADD_SERVER_EVENT', data:{[event]:msg}})