const bad_modems =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_BAD_MODEMS":
            return [
                ...action.data
            ];
        case "ADD_BAD_MODEM":
        case "UPDATE_BAD_MODEM":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "UPDATE_BAD_MODEM_BY_IMEI":
            const {imei, applyStatus,ext_ip, applyError} = action.data;
            return state.map(c => {
                    if(c.IMEI !== imei){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_BAD_MODEM" :
            return state.filter(
                c => c.imei !== action.data.imei&&c.IMEI !== action.data.imei
            )

        default :
            return state
    }
}
export default bad_modems;

export const loadBadModems = data =>({type:'LOAD_BAD_MODEMS', data})
export const addBadModem = data =>({type:'ADD_BAD_MODEM', data})
export const updateBadModem = data =>({type:'UPDATE_BAD_MODEM', data})
export const updateBadModemByImei = data =>({type:'UPDATE_BAD_MODEM_BY_IMEI', data})
export const deleteBadModem = imei =>({type:'DELETE_BAD_MODEM', data:{imei}});
