//https://codesandbox.io/p/devbox/tanstack-table-example-pagination-gyupo9?file=%2Fsrc%2Fmain.tsx%3A217%2C18-217%2C54
import React,{useState, useEffect} from 'react';
import "./paginator.scss";

const filterPages = (visiblePages:number[], totalPages:number) => {
    return visiblePages.filter(page => page <= totalPages);
};

const getVisiblePages = (page:number|null, total:number) => {
    if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
        if(!page){
            return [1, 2, 3, 4, 5, total];
        }

        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
        } else {
            return [1, 2, 3, 4, 5, total];
        }
    }
};
interface PaginatorProps{
    gotoPage:(x:number)=>void,
    canPreviousPage:boolean,
    canNextPage:boolean,
    pageCount:number,
    pageIndex:number,
    pageOptions?:any,
    setPageSize:(x:number)=>void,
    previousPage:()=>void,
    nextPage:()=>void,
    pageSize:number
}
const Paginator = (props:PaginatorProps) => {
    const {
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        pageIndex,
        pageOptions,
        setPageSize,
        previousPage,
        nextPage,
        pageSize} = props;

    const vp = getVisiblePages(null,pageCount);
    const [visiblePages, setVisiblePages] = useState(vp);

    useEffect(()=>{
        const vp = getVisiblePages(null,pageCount);
        setVisiblePages(vp);
        changePage(pageIndex+1);

    },[pageCount]);

    const changePage = (page:number) => {
        const activePage = pageIndex + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(page, pageCount);

        const vp = filterPages(visiblePages, pageCount);
        setVisiblePages(vp);

        gotoPage(page - 1);
    }
    const activePage = pageIndex + 1;
    return (
        <div className="paginator">
            <div className='form-group selector'>
                <label className='col'>Page size </label>
                <select
                    className='form-control'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 15, 20, 30, 40, 50, 500, 1000, 5000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <div className='next-previous'>
                <button className='btn btn-link' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'Previous'}
                </button>
                {' '}
                <div className={"Table__visiblePagesWrapper"}>
                    {visiblePages.map((page, index, array) => {
                        return (
                            <button
                                key={page}
                                className={
                                    activePage === page
                                        ? "btn btn-link disabled"
                                        : "btn btn-link"
                                }
                                onClick={()=>changePage( page)}
                            >
                                {array[index - 1] + 2 < page ? `...${page}` : page}
                            </button>
                        );
                    })}
                </div>

                <button className='btn btn-link' onClick={() => nextPage()} disabled={!canNextPage}>
                    {'Next'}
                </button>
            </div>

        </div>
    )
}
export default Paginator;

