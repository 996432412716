import {endpoint} from "./index";
import {fetch_options} from "./header";

export function getLanguagesAPI() {
    return fetch(endpoint+'/languages',fetch_options).then(r=>r.json());
}
export function addLanguageAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/languages`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateLanguageAPI(code,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/languages/${code}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteLanguageAPI(code){
    return fetch(`${endpoint}/languages/${code}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}