import {logout} from "../api";
import RenderInRoot from "../components/Helpers/RenderInRoot";
import React, {useEffect, useMemo, useState} from "react";
import sockets from "../utils/sockets";
import {Link} from "react-router-dom";

const TemplateHeader = props =>{
    const [headerInfo, setHeaderInfo] = useState('');
    const [totalBalance, setTotalBalance] = useState();
    const [lastPayment, setLastPayment] = useState();
    const [expiredModems, setExpiredModems] = useState([]);
    const onLogout = () =>{
        logout().finally(r=>{
            window.location="/sign-in"
        });

    }

    const expiredModemsIn4DaysListener = (data)=>{
        setExpiredModems(data)
    }
    const updateBalanceListener = (data)=>{
        setTotalBalance(data)
    }
    const updateLastPaymentInfoListener = (data)=>{
        setLastPayment(data)
    }
    const updateHeaderInfoListener = (data)=>{
        setHeaderInfo(data);
    }
    useEffect(()=>{
        sockets.getInstance().addListener('balanceInfo',updateBalanceListener);
        sockets.getInstance().addListener('headerInfo',updateHeaderInfoListener);
        sockets.getInstance().addListener('lastPaymentInfo',updateLastPaymentInfoListener);
        sockets.getInstance().addListener('expiredModemsIn4Days',expiredModemsIn4DaysListener);
        return ()=>{
            sockets.getInstance().removeListener('balance',updateBalanceListener);
            sockets.getInstance().removeListener('headerInfo',updateHeaderInfoListener);
            sockets.getInstance().removeListener('lastPaymentInfo',updateLastPaymentInfoListener);
            sockets.getInstance().removeListener('expiredModemsIn4Days',expiredModemsIn4DaysListener);
        }
    })
    const lastPaymentAmount = useMemo(()=>{
        let payment =  lastPayment?.amount||lastPayment?.data?.amount||0;
        if(lastPayment?.provider==='stripe'){
            payment/=100;
        }
        return payment
    }, [lastPayment]);
    const sendMessagesToUsers = ()=>{
        const users = expiredModems.reduce((a,modem)=>{
           if(!a.includes(modem._ownerId)){
               a.push(modem._ownerId);
           }
           return a;
        },[])
        window.alert(`Sending messages to ${users.length} users!`);
    }
    return(
        <header className="header col-auto">
            <div className="row g-0 align-items-center flex-nowrap">
                <div className="col-auto d-md-none">
                    <button className="toggle-menu">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>
                </div>
                <div className="col-auto d-none d-md-block">
                    <div className="welcome">Welcome <span className="name"></span></div>
                </div>
                {totalBalance&&
                    <>
                        <div className='col text-center d-flex flex-column w-auto'
                             style={{maxHeight: 50, paddingLeft: 50, minWidth: 300}}>
                            <div className='d-flex flex-nowrap'>
                                <span className='col-3 text-start'>BTC:</span>
                                <span>${(totalBalance.btc_inner || 0).toFixed(2)} / ${(totalBalance.btc_blockchain_balance || 0).toFixed(2)}</span>
                            </div>
                            <div className='d-flex flex-nowrap'>
                                <span className='col-3 text-start'>USDT:</span>
                                <span>${(totalBalance.usdt_inner || 0).toFixed(2)} / ${(totalBalance.usdt_blockchain_balance || 0).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className='col text-center d-flex flex-column w-auto'
                             style={{maxHeight: 50, paddingLeft: 50, minWidth: 300}}>
                            <div className='d-flex flex-nowrap'>
                                <span className='col-3 text-start'>TRX:</span>
                                <span className={totalBalance.trx<30?`text-danger`:'text-success'}>{(totalBalance.trx || 0).toFixed(2)}</span>
                            </div>
                        </div>
                    </>

                }
                {lastPayment && <div className='col  text-center d-flex flex-column w-auto'
                                     style={{maxHeight: 50, paddingLeft: 0, minWidth: 450}}>
                    <div className='text-start'>Last payment
                        [{lastPayment.provider} / {lastPayment?.data?.coin || 'USD'}] {(new Date(lastPayment.updatedAt * 1000)).toLocaleTimeString()}</div>
                    <div className='text-start'><Link to={`/customers/${lastPayment.user._id}`}
                                                      className=''>{lastPayment.user.name} {'<'}{lastPayment.user.login}{'>'}</Link> -
                        ${(lastPaymentAmount).toFixed(2)} </div>
                </div>}
                {/* {expiredModems.length>0&&
                    <div className='col  text-center d-flex flex-column w-auto' style={{maxHeight: 50, minWidth: 260}}>
                        <div className='text-start'>{expiredModems.length} modems will expired in 4 days</div>
                        <button  onClick={sendMessagesToUsers} className='btn btn-sm btn-info text-star'>Send messages to users</button>
                    </div>
                    }*/}
                <div className='col text-info text-center'>{headerInfo || ''}</div>
                <div className="col right-pan">
                    <a href={"#"} onClick={onLogout} className="logout text-white">Log Out</a>
                </div>
            </div>
        </header>
    )
}
export default TemplateHeader