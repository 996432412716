const modems =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_MODEMS":
            return [
                ...action.data
            ];
        case "ADD_MODEM":
        case "UPDATE_MODEM":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "UPDATE_MODEMS":
            return state.map(c => {
                    const m = action.data.find(f => c._id === f._id);
                    if (!m) {
                        return c;
                    } else {
                        return {...c, ...m}
                    }

                }
            )
        case "UPDATE_MODEM_BY_IMEI":
            let {imei, applyStatus,extIp, rotateStatus,pingStatus,ts} = action.data;
            return state.map(c => {
                    if(c.IMEI !== imei){
                        return c;
                    }
                    const d =  {
                        ...c,
                        ts,
                        tsFormated:ts?(new Date(ts)).toLocaleTimeString():''
                    };
                    if(applyStatus){
                        d.applyStatus=applyStatus;
                    }
                    if(extIp){
                        d.ext_ip=extIp;
                    }
                    if(pingStatus){
                        d.pingStatus=pingStatus;
                    }
                    if(rotateStatus){
                        d.rotateStatus=rotateStatus;
                    }
                    return d;
                }
            )
        case "UPDATE_MODEMS_BY_IMEI":

            return state.map(c => {
                const m = action.data.find(f => c.IMEI === f.imei,{});
                if(!m){
                    return c;
                }
                let {imei, applyStatus,extIp, rotateStatus,pingStatus,ts} = m;
                    if(!imei){
                        return c;
                    }
                    const d =  {
                        ...c,
                        ts,
                        tsFormated:ts?(new Date(ts)).toLocaleTimeString():''
                    };
                    if(applyStatus){
                        d.applyStatus=applyStatus;
                    }
                    if(extIp){
                        d.ext_ip=extIp;
                    }
                    if(pingStatus){
                        d.pingStatus=pingStatus;
                    }
                    if(rotateStatus){
                        d.rotateStatus=rotateStatus;
                    }
                    return d;
                }
            )
        case "DELETE_MODEM" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default modems;

export const loadModems = data =>({type:'LOAD_MODEMS', data})
export const addModem = data =>({type:'ADD_MODEM', data})
export const updateModem = data =>({type:'UPDATE_MODEM', data})
export const updateModems = data =>({type:'UPDATE_MODEMS', data})
export const updateModemByImei = data =>({type:'UPDATE_MODEM_BY_IMEI', data})
export const updateModemsByImei = data =>({type:'UPDATE_MODEMS_BY_IMEI', data})
export const deleteModem = _id =>({type:'DELETE_MODEM', data:{_id}});
