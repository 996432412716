import React from "react";
import {Link, useLocation, useParams} from "react-router-dom";
const LeftMenu = props =>{
    const location = useLocation();

   // const router = useRouter();
    const isActive= link =>{
        let has = location.pathname===link;
        if(!has){
            has = location.pathname.indexOf(link)===0;
        }
        return (has===true)?'width-icn active':'width-icn'
    }
    return(
        <div className="col-auto bar-col">
            <div className="row g-0 flex-column h-100" style={{maxHeight:"calc(100vh - 70px)",overflow: "auto"}}>
                <nav className="main-nav col">
                    <Link to={"/modems"} className={isActive('/modems')}>
                        <i className='fad fa-router'></i> Modems
                    </Link>
                    <Link to={"/customers"} className={isActive('/customers')}>
                        <i className='fad fa-users'></i> Customers
                    </Link>
                    <Link to={"/countries"} className={isActive('/countries')}>
                        <i className='fad fa-globe-europe'></i> Countries
                    </Link>
                    <Link to={"/servers"} className={isActive('/servers')}>
                        <i className='fad fa-server'></i> Servers
                    </Link>{/*
                    <Link to={"/tariffs"} className={isActive('/tariffs')}>
                        <i  className='fad fa-dollar-sign'></i> Tarrifs
                    </Link>*/}

                    {/*  <Link to={"/languages"} className={isActive('/languages')}>
                        <i  className='fad fa-language'></i> Languages
                    </Link>*/}
                    <Link to={"/coupons"} className={isActive('/coupon')}>
                        <i className='fad fa-badge-percent'></i> Coupons
                    </Link>
                  {/*  <Link to={"/tariff-options"} className={isActive('/tariff-options')}>
                        <i className='far fa-funnel-dollar'></i> Tariff options
                    </Link>*/}
                    {/* <Link to={"/settings"} className={isActive('/settings')}>
                        <i  className='fad fa-cog'></i> Settings
                    </Link>*/}
                  {/*  <Link to={"/account"} className={isActive('/account')}>
                        <i className='fad fa-file-user'></i> Account
                    </Link>*/}
                    <Link to={"/billing"} className={isActive('/billing')}>
                        <i className='fas fa-sack-dollar'></i> Billing
                    </Link>
                    <Link to={"/payments"} className={isActive('/payments')}>
                        <i className='fas fa-money-check-edit-alt'></i> Payments
                    </Link>
                    <Link to={"/payments-issues"} className={isActive('/payments-issues')} style={{color: "red"}}>
                        <i className='fas fa-bug'></i> Issues
                    </Link>
                    <Link to={"/tools"} className={isActive('/tools')}>
                        <i className='far fa-cog'></i> Tools
                    </Link>
                  {/*  <hr/>
                    <Link to={"/apply-log"} className={isActive('/apply-log')}>
                        <i className='far fa-users-cog'></i> Logs
                    </Link>*/}
                    <hr/>
                    <Link to={"/telegram-chat"} className={isActive('/telegram-chat')}>
                        <i className='fad fa-user-md-chat'></i> Chat
                    </Link>

                    {/* <Link to={"/tariffs-gb"} className={isActive('/tariffs-gb')}>
                        <i  className='far fa-funnel-dollar'></i> Tariffs GB
                    </Link>
                    <Link to={"/backend-ports"} className={isActive('/backend-ports')}>
                        <i  className='fal fa-network-wired'></i> Backend ports
                    </Link>*/}
                </nav>
                <div className="footer col-auto">
                    <img src="/assets/images/logo_w.svg" alt="Coronium"/>
                    <div className="copy">© 2020 ALL RIGHTS RESERVED.</div>
                </div>
            </div>
        </div>
    )
}

export default LeftMenu;