const applySettingsLogReducer = (state = [], action:any) => {
    const duplicate = state.some((row:any) => (row._id === action.data._id));
    switch (action.type) {
        case "ADD_APPLY_SETTINGS_LOG":
        case "UPDATE_APPLY_SETTINGS_LOG":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_APPLY_SETTINGS_LOG":
            return state.filter(
                (c:any) => c._id !== action.data._id
            )
        case "LOAD_APPLY_SETTINGS_LOG":
            return action.data;
        default:
            return state;
    }


}
export const loadApplySettingsLog = (data:any) => ({type: "LOAD_APPLY_SETTINGS_LOG", data})
export const updateApplySettingsLog = (data:any) => ({type: "UPDATE_APPLY_SETTINGS_LOG", data});
export default applySettingsLogReducer;