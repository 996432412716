import {endpoint} from "./index";
import {fetch_options} from "./header";

export function assignServerAPI({server_id, user_id}) {
    let body = JSON.stringify({user_id});
    return fetch(`${endpoint}/servers/${server_id}/assign`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function releaseServerAPI(server_id) {
    return fetch(`${endpoint}/servers/${server_id}/release`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

export function getServersAPI() {
    return fetch(endpoint+'/servers',fetch_options).then(r=>r.json());
}

export function addServerAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/servers`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateServerAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/servers/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteServerAPI(_id){
    return fetch(`${endpoint}/servers/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
