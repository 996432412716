
const EmailForPasswordReset = props =>{
    return(
        <div className="app-box d-flex flex-column">
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <a>
                                <img src="/assets/images/sign.svg" alt="Follow"/>
                            </a>
                        </div>
                        <div className="col-auto ml-auto">
                            <a>
                                <img src="/assets/images/close.svg" alt="Close"/>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <main className="main pt-4">
                <div className="container">
                    <div className="sub-title mb-2">Reset Password</div>

                    <div className="cpanel c-blue">
                        <div className="txt">Enter your e-mail to reset password.</div>
                    </div>

                    <form action="#" method="post" className="">
                        <div className="inp-group">
                            <label>E-mail</label>
                            <input type="email" name="email" placeholder="example@example.com"/>
                        </div>
                    </form>
                </div>
            </main>
            <footer className="footer mt-auto">
                <div className="container">
                    <div className="row g-0 align-items-center">
                        <a className="controls col">Reset</a>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default EmailForPasswordReset;