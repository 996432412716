const exchange_rates =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_EXCHANGE_RATES":
            return  action.data.reduce((a,{symbol,price})=>{a[symbol]=parseFloat(price);return a},{})
        default :
            return state
    }
}
export default exchange_rates;

export const loadExchangeRates = data =>({type:'LOAD_EXCHANGE_RATES', data})