import React from 'react';
import Helmet from "react-helmet";
import {Slide, ToastContainer} from "react-toastify";
import BackendPorts from "../components/BackendPorts";

function BackendPortsPage() {
    return (
        <>
            <Helmet>
                <meta name="title" content={`Backend ports`} />
                <meta name="description" content={''} />
                <title>{`Backend ports`}</title>
            </Helmet>
            <BackendPorts/>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />

        </>
    );
}

export default BackendPortsPage;