import React from 'react';
import ReactSelect from "../../Libs/ReactSelect";

function BackendPortsFilters(props:any) {
    const onFilter =(filterName:string,data:any)=>{

    }
    return (
        <div className='filters'>
            <ReactSelect
                onChange={data=>onFilter('country',data)}
                placeholder={'Country'}
                classNamePrefix={'react-filter'}  />
            <ReactSelect
                onChange={data=>onFilter('server',data)}
                placeholder={'Server'}
                classNamePrefix={'react-filter'}  />
            <ReactSelect
                onChange={data=>onFilter('source',data)}
                placeholder={'Source'}
                classNamePrefix={'react-filter'}  />
        </div>
    );
}

export default BackendPortsFilters;