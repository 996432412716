const coupons =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_COUPONS":
            return [
                ...action.data
            ];
        case "ADD_COUPON":
        case "UPDATE_COUPON":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c.code !== action.data.code){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_COUPON" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default coupons;

export const loadCoupons = data =>({type:'LOAD_COUPONS', data})
export const addCoupon = data =>({type:'ADD_COUPON', data})
export const updateCoupon = data =>({type:'UPDATE_COUPON', data})
export const deleteCoupon = code =>({type:'DELETE_COUPON', data:{code}});
