import React, {ChangeEvent, useMemo} from 'react';
import styled, { css } from "styled-components";
import {Dropdown} from "react-bootstrap";
const ContextMenu = styled.div<{top:number, left:number}>`
  position: absolute;
  width: 200px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
    padding: 5px;
    border-color: black;
 color: black;   
    
  ${
    ({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
  ul {
    box-sizing: border-box;
    padding: 0 10px;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 1px 12px;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
      text-decoration: underline;
  //  background-color: deepskyblue;
      
  }
`;
interface Props{
    x:number,
    y:number,
    show?:boolean,
    columns:{columnId:string,visible:boolean}[],
    onCheck?:(event:ChangeEvent<HTMLInputElement>)=>void
}
function ColumnContextMenu(props:Props) {
    if(!props.show||!props.onCheck){
        return <></>
    }
  //  const columns = useMemo(()=>props.columns||[],[props.columns])
//    console.log(props.columns)
    return (
        <Dropdown.Menu show={true} style={{top:props.y, left:props.x}}>

            { props.columns.filter(f=>!!f.columnId).map(column=>{
                return (
                    <Dropdown.Item key={Math.random()}>
                        <div className='form-group'>
                            <input type='checkbox' checked={column.visible} onChange={props.onCheck}
                                   name={column.columnId} className='form-check-inline'/>
                            <label className='form-label'>{column.columnId}</label>
                        </div>

                    </Dropdown.Item>
                )
            })}
        </Dropdown.Menu>
    );
}

export default ColumnContextMenu;