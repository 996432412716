import {endpoint} from "./index";
import {fetch_options} from "./header";

export function getTariffsAPI() {
    return fetch(endpoint+'/tariffs',fetch_options).then(r=>r.json());
}
export function addTariffAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/tariffs`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateTariffAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/tariffs/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function orderTariffAPI(source_id,dst_id){
    let body = JSON.stringify({source_id,dst_id});
    return fetch(`${endpoint}/tariffs/order`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteTariffAPI(_id){
    return fetch(`${endpoint}/tariffs/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
