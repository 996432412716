import {endpoint} from "./index";
import {fetch_options} from "./header";

export function getAccountAPI() {
    return fetch(endpoint+'/account-admin').then(r=>r.json());
}

export function turnOnOff2faAPI(data) {
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/account/2fa`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
