import SignIn from "../components/Auth/sign-in";
import SignUp from "../components/Auth/sign-up";
import EmailForPasswordReset from "../components/Auth/email-for-password-reset";
import React, {lazy, Suspense} from "react";
import CustomerBootstrapTemplate from "../containers";
import Spinner from "../components/Spinner";
import {Routes} from "react-router";
import {Navigate, Route} from "react-router-dom";
import BackendPortsPage from "../pages/backend-ports-page";

const ModemsPage = lazy(()=>import("../pages/modems"));
const ModemPage = lazy(()=>import("../pages/modem"));

const CustomersPage = lazy(()=>import("../pages/customers"));
const CustomerPage = lazy(()=>import("../pages/customer"));
const GbCustomerPage = lazy(()=>import("../pages/gb-customer-page"));
const SettingsPage = lazy(()=>import("../pages/settings"));
const ServersPage = lazy(()=>import("../pages/servers"));
const CountriesPage = lazy(()=>import("../pages/countries"));
const CountryPage = lazy(()=>import("../pages/country-page"));
const TariffsPage = lazy(()=>import("../pages/tariffs"));
const TariffPage = lazy(()=>import("../pages/tariff-page"));
const TariffOptionsPage = lazy(()=>import("../pages/tariff-options-page"));
const LanguagePage = lazy(()=>import("../pages/languages"));
const AccountPage = lazy(()=>import("../pages/account-page"));
const CouponsPage = lazy(()=>import("../pages/coupons"));
const CouponPage = lazy(()=>import("../pages/coupon-page"));
const BillingPage = lazy(()=>import("../pages/billing-page"));
const PaymentIssuesPage = lazy(()=>import("../pages/payment-issues-page"));

const TariffsGbPage = lazy(()=>import("../pages/tariffs-gb-page"));
const BackendPorts = lazy(()=>import("../pages/backend-ports-page"));
const ToolsPage = lazy(()=>import("../pages/tools-page"));
const ApplyLogPage = lazy(()=>import("../pages/apply-log-page"));
const ChatPage = lazy(()=>import("../pages/chat-page"));
const PaymentsPage = lazy(()=>import("../pages/payments-page"));

const Routes1 = props=>{
    return(
        <Suspense fallback={<Spinner />}>
        <Routes>
            <Route path='/sign-in' element={<SignIn/>}/>
            <Route path='/sign-up' element={<SignUp/>} />
            <Route path='/email-for-password-reset' element={<EmailForPasswordReset/>} />
            <Route path={'/'} element={<CustomerBootstrapTemplate/>}>
                <Route exact path={'/modems'} element={<ModemsPage/>} />
                <Route exact path={'/modems/:modem_id'} element={<ModemPage/>} />
                <Route exact path={'/customers'} element={<CustomersPage/>} />
                <Route path={'/customers/:user_id'} element={<CustomerPage/>} />
                <Route path={'/gb-customers/:user_id'} element={<GbCustomerPage/>} />
                <Route path={'/settings'} element={<SettingsPage/>} />
                <Route path={'/account'} element={<AccountPage/>} />
                <Route path={'/languages'} element={<LanguagePage/>} />
                <Route path={'/servers'} element={<ServersPage/>} />
                <Route exact path={'/countries'} element={<CountriesPage/>} />
                <Route exact path={'/countries/:country_id'} element={<CountryPage/>} />
                <Route exact path={'/countries/:country_id/tariffs'} element={<TariffPage/>} />
                <Route path={'/tariffs-gb'} element={<TariffsGbPage/>} />
                <Route path={'/tariffs'} element={<TariffsPage/>} />
                <Route path={'/tariff-options'} element={<TariffOptionsPage/>} />
                <Route exact path={'/billing'} element={<BillingPage/>} />
                <Route exact path={'/coupons'} element={<CouponsPage/>} />
                <Route exact path={'/coupons/:coupon_id'} element={<CouponPage/>} />
                <Route exact path={'/payments-issues'} element={<PaymentIssuesPage/>} />
                <Route exact path={'/backend-ports'} element={<BackendPortsPage/>} />
                <Route exact path={'/tools'} element={<ToolsPage/>} />
                <Route exact path={'/apply-log'} element={<ApplyLogPage/>} />
                <Route exact path={'/telegram-chat'} element={<ChatPage/>} />
                <Route exact path={'/payments'} element={<PaymentsPage/>} />
                <Route
                    path="*"
                    element={<Navigate to={'/billing'}/>}
                />
            </Route>
            <Route path={"/:language/sign-in"} element={<SignIn/>}/>
        </Routes>
        </Suspense>
    )
}

export default Routes1;