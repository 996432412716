import Select from "react-select";
import React, {useEffect, useState} from "react";
import CSS from 'csstype';
interface ReactSelectProps{
    isMulti?:boolean,
    value?:any,
    initData?:any[]|any,
    style?:CSS.Properties,
    options?:any,
    onChange:(data:any)=>void;
    placeholder?:string;
    classNamePrefix?:string;
    default?:boolean;
    [x:string]:any
}
 const ReactSelect = (props:ReactSelectProps) => {
    const { isMulti, value, initData, options, placeholder } = props;
    const [data, setData] = useState<any[]>([]);
    const onDelete = (val:any)=>{
        const res = data.filter((f:any)=>f.value!==val).map((m:any)=>m.value);

        if(props.onChange){
            props.onChange(res);
        }
        setData(res);

    }
    const onChange = (val:any)=>{
        let res = null;
        if(props.default){
            return props.onChange(val);
        }
        setData(val);
        if(props.onChange){
            if(val){
                res = isMulti?val.map((m:any)=>m.value):val.value;
                props.onChange(res);
            }else {
                props.onChange(null);
            }

        }
    }
    useEffect(()=>{
        if(props.default){
            return ;//setData(value);
        }
        if(initData){
            let res = null;
            if(isMulti){
                res = options.filter((f:any)=>initData.includes(f.value))
            }else{
                res = options.find((f:any)=>f.value===initData,{});
            }

            setData(res);
        }
    },[initData])
    return (
        <>
            <Select {...props} controlShouldRenderValue={!isMulti} onChange={onChange} value={props.default?value:data}/>
            {isMulti && data &&
                <div className="d-flex flex-wrap align-items-center selected-tags mt-2">
                    {
                        data.map(val => (
                            <div className="cl-tag me-2 mb-1" key={val.value}>
                                {val.label} <a className="del-tag" style={{cursor:'pointer'}} onClick={e=>onDelete(val.value)}></a>
                            </div>))
                    }
                </div>
            }
        </>
    )
}
export default ReactSelect;
