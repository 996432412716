import {endpoint} from "./index";
import {fetch_options} from "./header";


export function getTokenAPI() {
    return fetch(endpoint+'/token').then(r=>r.json());
}


export async function checkCredentials(login, password, code){
    try{
        const result = await fetch(
            `${endpoint}/check-credentials`,
            {
                ...fetch_options,
                body:JSON.stringify({login,password, code})
                ,method:'POST'
            });
        const json = await result.json()
        json.status = result.status;
        return Promise.resolve(json);
    }catch (err){
        return Promise.reject(err);
    }
}

export function logout() {
    return fetch(endpoint+'/logout',{...fetch_options, method:'GET'}).then(r=>r.json());
}
export function ping() {
    return fetch(endpoint+'/ping').then(r=>r.json());
}