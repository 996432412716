import React, {MutableRefObject} from "react";
interface IndeterminateCheckbox{
    indeterminate:boolean|any;
    className?:string,
    [x:string]:any;
}
function IndeterminateCheckbox({indeterminate, className = '', ...rest}:IndeterminateCheckbox) {
    const ref:MutableRefObject<any> = React.useRef(null)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}
export const selectColumn = {
    id: 'select',
    enableSorting:false,
    size:10,
    maxSize:10,

    header: ({ table }:{table:any}) => (
        <IndeterminateCheckbox
            {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
/>
),
cell: ({ row }:{row:any}) => (
    <div className="">
        <IndeterminateCheckbox
            {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }}
/>
</div>
),
};