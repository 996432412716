const paymentTransactions =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_PAYMENT_TRANSACTIONS":
            return [
                ...action.data
            ];
        default :
            return state
    }
}
export default paymentTransactions;

export const loadPaymentTransactions = data =>({type:'LOAD_PAYMENT_TRANSACTIONS', data})