const languages =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_LANGUAGES":
            return [
                ...action.data
            ];
        case "ADD_LANGUAGE":
        case "UPDATE_LANGUAGE":
            let duplicate = state.some(row=>(row.code===action.data.code));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c.code !== action.data.code){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_LANGUAGE" :
            return state.filter(
                c => c.code !== action.data.code
            )

        default :
            return state
    }
}
export default languages;

export const loadLanguages = data =>({type:'LOAD_LANGUAGES', data})
export const addLanguage = data =>({type:'ADD_LANGUAGE', data})
export const updateLanguage = data =>({type:'UPDATE_LANGUAGE', data})
export const deleteLanguage = code =>({type:'DELETE_LANGUAGE', data:{code}});
