import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useAuthUser} from "../../utils/AuthUser";
//import {useHistory} from "react-router";
import "./sign-in.scss";
import {checkCredentials} from "../../api";
import logo from "./logo.svg";
import "./auth.scss"

const SignIn = props =>{
    const { setLogged, logged, returnUrl } = useAuthUser();
    const [error, setError] = useState();
    const [step, setStep] = useState(1);
  //  const history = useHistory();
    const form = useRef();
    const redirect = returnUrl&&returnUrl.length>2?returnUrl:'/';
    useEffect(()=>{
        setStep(1);
    },[])

/*    const onSubmit = async event =>{
        event.preventDefault();
        if(step===2){
            return form.current.submit();
        }
        const login = form.current.elements["login"].value;
        const password = form.current.elements["password"].value;
        setError(undefined);
        try{
            const d = await checkCredentials(login,password);
            if(d.error){
                if(d.isActive!==undefined&&!d.isActive){
                    return setError('Your account deactivated by admin!');
                }else{
                    return setError(d.error);
                }
            }else{
                setError(undefined);
                if(d.use2fa===true){
                    setStep(2);
                }else{
                    return form.current.submit();
                }
            }
        }catch (err){
            setError(err.message)
        }
    }*/
    const onSubmit = async event =>{
        event.preventDefault();
        const login = form.current.elements["login"].value;
        const password = form.current.elements["password"].value;
        const code = form.current.elements["code"].value;

        if(step===2){
            try{
                const {error:err} = await checkCredentials(login,password, code);
                if(err){
                    setError(err);
                }else{
                    return form.current.submit();
                }
            }catch (err){
                setError(err.message);
            }
            return
        }

        setError(undefined);
        try{
            const d = await checkCredentials(login,password);
            if(d.status===404){
                return setError('Account not found!');
            }
            if(!d.isSuperAdmin){
                return setError('Access denied!');
            }
            if(d.error){
                if(d.isActive===false){
                    return setError('Your account deactivated by admin!');
                }
            }else{
                setError(undefined);
                if(d.use2fa){
                    setStep(2);
                }else{
                    return form.current.submit();
                }


                // setLogged(true);
            }
        }catch (err){
            setError(err.message)
        }
    }
    return (
        <>
            <div className="sidenav">
                <div className='w-100 text-center'>
                    <img src={logo}/>
                </div>

                <div className="login-main-text">
                    <h2>Access our<br/>dashboard and enjoy:</h2>
                    <span>
                        Automated billing
                        <ul>
                            <li>Pay using Bitcoin or Credit Card</li>
                            <li>Subscribe or do one time payment</li>
                            <li>Renew anytime you want by adding custom days</li>
                        </ul>

                    </span>
                    <span>
                        Proxy management tools
                        <ul>
                            <li>API</li>
                            <li>Interval rotations</li>
                            <li>IP Whitelisting</li>
                        </ul>

                    </span>
                </div>
                <footer>
                    <a href={'/'}>← Exit dashboard</a>
                </footer>
            </div>
            <div className="main1">
                <main className="auth-main col-md-9 col-lg-6 col-sm-12">
                    <div className="title">Log In</div>

                    <form ref={form} onSubmit={onSubmit}  action='/signin' method='POST' className="auth-form">
                        <input type={'hidden'} name={'redirect'} value={redirect}/>
                        <div className={`inp-block mb-3 ${step===2&&'d-none'}`}>
                            <label htmlFor="email">Your email</label>
                            <input type="email" name="login" id="email" placeholder="name@coronium.io"/>
                        </div>
                        <div className={`inp-block mb-3 ${step===2&&'d-none'}`}>
                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" id="password" placeholder="At least 8 characters"/>
                        </div>
                        <div className={`inp-block mb-3 ${step===1&&'d-none'}`}>
                            <label htmlFor="password">Code</label>
                            <input type="text" name="code" id="code" placeholder="Google auth code"/>
                        </div>
                        {error&&<p className='text-danger'>{error}</p>}


                        <div className="controls">
                            <button type="submit" className="cl-btn w-100">Log in</button>
                        </div>
                    </form>
                </main>

            </div>
        </>
    )
}

export default SignIn;