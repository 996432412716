import {ApiData, endpoint, fetch_options} from "./helper";

const url = `${endpoint}/telegram`;
export async function getTelegramMessages():Promise<ApiData>{
    return fetch(`${url}/messages`,{...fetch_options, method:'GET'}).then(r=>r.json())
}

export async function getTelegramUsers():Promise<ApiData>{
    return fetch(`${url}/users`,{...fetch_options, method:'GET'}).then(r=>r.json())
}

export async function sendTelegramMessageAPI(data:any){
    const body = JSON.stringify(data);
    return fetch(`${url}/messages`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}