import {endpoint} from "./index";
import {fetch_options} from "./header";

/***************************************** modems *************************************/
export function deleteModemClonesAPI(_id){
    return fetch(`${endpoint}/modems/${_id}/clones`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function deleteModemCloneAPI(_id){
    return fetch(`${endpoint}/modems/${_id}/clone`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function updateCloneModemAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems/${_id}/clones`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function cloneModemAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems/${_id}/clones`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function getModemCountAPI({country_id, carrier_id,region_id}) {
    const carrier = carrier_id?`?carrier_id=${carrier_id}`:'';
    let region = region_id?`region_id=${region_id}`:'';
    region = carrier?'&'+region:region;
    return fetch(endpoint+`/modems/count/${country_id}`+carrier+region,fetch_options).then(r=>r.json());
}
export function getModemsAPI() {
    return fetch(endpoint+'/modems/admin',fetch_options).then(r=>r.json());
}

export function addModemAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateModemAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteModemAPI(_id){
    return fetch(`${endpoint}/modems/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

export function releaseModemsAPI(){
    return fetch(`${endpoint}/modems/release`,{...fetch_options, method:'POST'}).then(r=>r.json())
}

export function releaseModemAPI(modem_id){
    return fetch(`${endpoint}/modems/${modem_id}/release`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export function addModemsAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems/batch`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function addDaysToModemAPI(modem_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/modems/${modem_id}/add-days`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}


export function applySettingsToModemAPI(_id,ids){
    let body = JSON.stringify({ids});
    return fetch(`${endpoint}/modems/${_id}/apply-settings`,{...fetch_options, body, method:'POST'}).then(r=>r.json())
}

export function getActiveApplySettingsLogAPI(){
    return fetch(`${endpoint}/modems/active-apply-settings-log`,{...fetch_options,  method:'GET'}).then(r=>r.json())
}
export function getBadModemListAPI(){
    return fetch(`${endpoint}/modems/bad-modem-list`,{...fetch_options,  method:'GET'}).then(r=>r.json())
}
export function getApplySettingsLogAPI(){
    return fetch(`${endpoint}/modems/apply-settings-log`,{...fetch_options,  method:'GET'}).then(r=>r.json())
}
export function restartModemAPI(_id){
    return fetch(`${endpoint}/modems/${_id}/restart`,{...fetch_options,method:'POST'}, ).then(r=>r.json())
}

export async function testModemAPI(modemId){
    return fetch(`${endpoint}/modems/${modemId}/test`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export async function markModemAsIssueAPI(modemId){
    return fetch(`${endpoint}/modems/${modemId}/mark-as-issue`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export async function testModemSpeedAPI(modemId){
    return fetch(`${endpoint}/modems/${modemId}/speed`,{...fetch_options, method:'POST'}).then(r=>r.json())
}