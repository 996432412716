const tariffsGb = (state = [], action:any) => {
    const duplicate = state.some((row:any) => (row._id === action.data._id));
    switch (action.type) {
        case "ADD_TARIFF_GB":
        case "UPDATE_TARIFF_GB":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_TARIFF_GB":
            return state.filter(
                (c:any) => c._id !== action.data._id
            )
        case "LOAD_TARIFFS_GB":
            return action.data;
        default:
            return state;
    }


}
export const loadTariffsGb = (data:any) => ({type: "LOAD_TARIFFS_GB", data})
export const updateTariffGb = (data:any) => ({type: "UPDATE_TARIFF_GB", data});
export const addTariffGb = (data:any) => ({type: "ADD_TARIFF_GB", data});
export const deleteTariffGb = (_id:string) => ({type: "DELETE_TARIFF_GB", data: {_id}});
export default tariffsGb;