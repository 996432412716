import React, {useContext,  useState} from 'react';
import RenderInRoot from "../Helpers/RenderInRoot";
import "./preloader.scss";
import preloader_img from './preloader.svg';
const PreloaderContext = React.createContext()
const Preloader = props => {
    const [preloader, showPreloader] = useState(false);
    return (
        <>
            <RenderInRoot>
                <div className={`preloader ${preloader?'active':''}`} onClick={()=>showPreloader(false)}>
                    <img src={preloader_img}/>
                </div>
            </RenderInRoot>
            <PreloaderContext.Provider value={{ showPreloader }}>{props.children}</PreloaderContext.Provider>
        </>

    );
};

export default Preloader;

export const usePreloader = () => {
    return useContext(PreloaderContext)
}