import {endpoint} from "./index";
import {fetch_options} from "./header";

export function getRolesAPI() {
    return fetch(endpoint+'/users/roles',fetch_options).then(r=>r.json());
}
export function getUsersAPI(limit=300000,showAll=false) {
    return fetch(`${endpoint}/users?limit=${limit}&showAll=${showAll}`,fetch_options).then(r=>r.json());
}
export function getUserAPI(user_id) {
    return fetch(`${endpoint}/users/${user_id}`,fetch_options).then(r=>r.json());
}
export function getUserTokenAPI(user_id) {
    return fetch(`${endpoint}/users/${user_id}/auth-token`,fetch_options).then(r=>r.json());
}
export function addUserAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/users`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateUserAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/users/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function updateAffiliateBonusAPI(_id,affiliateBonus){
    let body = JSON.stringify({affiliateBonus});
    return fetch(`${endpoint}/users/${_id}/affiliate-bonus`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function payloadAffiliateBonusAPI(_id,affiliateBonus){
    let body = JSON.stringify({affiliateBonus});
    return fetch(`${endpoint}/users/${_id}/payload-affiliate-bonus`,{...fetch_options, method:'POST',body}).then(r=>r.json())
}

export function deleteUserAPI(_id){
    return fetch(`${endpoint}/users/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function setContractSignedAPI(userId,contractSigned) {
    let body = JSON.stringify({contractSigned});
    return fetch(`${endpoint}/users/${userId}/setContractSigned`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function createFarmerAPI(userId) {
    let body = JSON.stringify({});
    return fetch(`${endpoint}/users/${userId}/create-farmer`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function switchToUserAPI(userId) {
    return fetch(`${endpoint}/users/${userId}/switch`,{...fetch_options, method:'POST'})
}