const settings =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_SETTINGS":
            return {
                ...action.data
            };
        default :
            return state
    }
}
export default settings;

export const loadSettings = data =>({type:'LOAD_SETTINGS', data})