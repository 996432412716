const regions =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_REGIONS":
            return [
                ...action.data
            ];
        default :
            return state
    }
}
export default regions;

export const loadRegions = data =>({type:'LOAD_REGIONS', data})