import {endpoint} from "./index";
import {fetch_options} from "./header";


export function getSettingsAPI() {
    return fetch(endpoint+'/settings/admin',fetch_options).then(r=>r.json());
}

export function updateSettingsAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/settings`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}