import React, {useMemo} from 'react';
import "./backend-ports.scss";
import ReactTable from "../../Libs/ReactTable";
import {useSelector} from "react-redux";
import backendPortsColumns from "./backend-ports-columns";
import BackendPortsFilters from "./backend-ports-filters";
interface IBackendPortsData{
    backendPorts?:any[]
}
function BackendPorts() {
    const {backendPorts=[]}:any = useSelector(s=>s);
    const columns = useMemo(()=>backendPortsColumns({}),[]);
    return (
        <>
            <div className='d-flex w-100 backend-ports'>
                <div className='d-flex flex-column'>
                    <h1>Back-End Ports Currently Active</h1>
                    <BackendPortsFilters/>
                </div>
                <div className='d-flex flex-column'>
                    <h1>Admin Actions</h1>
                    <button className='btn'>Test All Proxies</button>
                </div>
            </div>
            <ReactTable data={backendPorts} columns={columns} style={{marginTop:30}}/>
        </>

    );
}

export default BackendPorts;
