import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.min.css';
import { Provider } from 'react-redux';
import configureStore from "./store/configureStore";
import {AuthUserProvider, useAuthUser} from "./utils/AuthUser";
import Routes from "./routes";
import Preloader from "./components/Preloader";
import {useEffect} from "react";

const store = configureStore()

function App(props) {
  return (
      <Provider store={store}>
          <Preloader>
            <AuthUserProvider store={store}>
              <Routes {...props} />
            </AuthUserProvider>
          </Preloader>
      </Provider>
  );
}
export default App;
