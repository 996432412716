const servers =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_SERVERS":
            return [
                ...action.data
            ];
        case "ADD_SERVER":
        case "UPDATE_SERVER":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_SERVER" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default servers;

export const loadServers = data =>({type:'LOAD_SERVERS', data})
export const addServer = data =>({type:'ADD_SERVER', data})
export const updateServer = data =>({type:'UPDATE_SERVER', data})
export const deleteServer = _id =>({type:'DELETE_SERVER', data:{_id}});
