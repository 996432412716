import React, {useEffect} from "react";
import "bootstrap/scss/bootstrap.scss"
import "./template.scss"
//import "./preloader.scss"
import TemplateHeader from "./template-header";
import LeftMenu from "./left-menu";
import {Outlet} from "react-router";
import sockets from "../utils/sockets";
import {updateModem, updateModemByImei, updateModems, updateModemsByImei} from "../reducers/modems";
import {useDispatch} from "react-redux";
import {updateActiveApplySettingsLog} from "../reducers/active-apply-settings-log-reducer";
import {addBadModem, deleteBadModem} from "../reducers/bad-modems";
import {addTelegramMessage} from "../reducers/telegram-message-reducer";


const CustomerBootstrapTemplate = props =>{
    const dispatch = useDispatch();
    let updates = [];
    const timer = setInterval(()=>{
            if(updates.length>0){
                dispatch(updateModemsByImei(updates));
                updates=[];
            }
    },3000)

    const pingStatus = (modems)=>{
       /* if(!Array.isArray(modems)){
            console.log(modems)
            dispatch(updateModemByImei({
                imei:modems.imei,
                pingStatus:modems,
            }));
            return;
        }*/
        dispatch(updateModems(modems))
        /*for(const modem of modems){
            dispatch(updateModem(modem));
        }*/

    }
    const telegramMessageUpdates = data =>{
        if(!data.error){
            dispatch(addTelegramMessage(data));
        }
    }
    const rotateStatus = (data)=>{
        /*dispatch(updateModemByImei({
            imei:data.imei,
            rotateStatus:data,
        }))*/
        updates.push({
            imei:data.imei,
            rotateStatus:data,
        })
    }
    const applyStatus = (data)=>{
        updates.push({
            imei:data.imei,
            applyStatus:data,
        });
      /*  dispatch(updateModemByImei({
            imei:data.imei,
            applyStatus:data,
        }))*/
    }
    useEffect(() => {
       /* sockets.getInstance().addListener('applySettingToModemPending',applySettingToModemProcessing);
        sockets.getInstance().addListener('applySettingToModemProgress',applySettingToModemProcessing);
        sockets.getInstance().addListener('applySettingToModemCompleted',applySettingToModemProcessing);

        sockets.getInstance().addListener('rotationModemCompleted',rotationModemCompleted);*/

        sockets.getInstance().addListener('rotateStatus',rotateStatus);
        sockets.getInstance().addListener('rotateStatusProgress',rotateStatus);

        sockets.getInstance().addListener('applyStatus',applyStatus);
        sockets.getInstance().addListener('applyStatusProgress',applyStatus);

        sockets.getInstance().addListener('pingStatus',pingStatus);
        sockets.getInstance().addListener('telegramMessage',telegramMessageUpdates);

        return ()=>{
           /* sockets.getInstance().removeListener('applySettingToModemPending',applySettingToModemProcessing);
            sockets.getInstance().removeListener('applySettingToModemProgress',applySettingToModemProcessing);
            sockets.getInstance().removeListener('applySettingToModemCompleted',applySettingToModemProcessing);

            sockets.getInstance().removeListener('rotationModemCompleted',rotationModemCompleted);*/

            sockets.getInstance().removeListener('rotateStatus',rotateStatus);
            sockets.getInstance().removeListener('rotateStatusProgress',rotateStatus);

            sockets.getInstance().removeListener('applyStatus',applyStatus);
            sockets.getInstance().removeListener('applyStatusProgress',applyStatus);

            sockets.getInstance().removeListener('pingStatus',pingStatus);
            sockets.getInstance().removeListener('telegramMessage',telegramMessageUpdates);

        }
    }, []);
     return(
        /*<SnackbarProvider hideIconVariant={false} preventDuplicate={true} autoHideDuration={5000}>*/
            <div className="row g-0 flex-column vh-100">
                <TemplateHeader/>
                <div className="row g-0 align-items-stretch col main-row">
                    <LeftMenu/>
                    <div className="col main-col">
                        <main className="main">
                            <Outlet />
                            {/* {props.children}*/}
                        </main>
                    </div>
                </div>
            </div>
        /*</SnackbarProvider>*/
    )
}

export default CustomerBootstrapTemplate