import React, { useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import {getExchangeRateAPI, getTokenAPI, logout, ping} from "../api";
import Sockets from "./sockets";
import {useDispatch} from "react-redux";
import {addServerEvent} from "../reducers/server-events";
import {loadExchangeRates} from "../reducers/exchange-rates";
import {loadUsers} from "../reducers/users";


const sockets = new Sockets();
const ENDPOINT = window.location.origin;//"http://127.0.0.1:5000";
const AuthUserContext = React.createContext()

export const AuthUserProvider = ({ children, store }) => {
  const cookieLogged = Cookies.get()
  const [logged, setLogged] = useState(cookieLogged ? true : false);
  const [returnUrl, setReturnUrl] = useState('/')
  const dispatch = useDispatch()
  const socketEvents = (event,msg)=>{
    switch (event){
      case 'users':dispatch(loadUsers(msg));break;
      default:
        dispatch(addServerEvent(event,msg));
    }

  }
  const checkAuth = async ()=>{
    if(!sockets.isConnected()){
      let {error,token} = await getTokenAPI();
      if(error){
        return console.error(error)
      }
      sockets.connect(ENDPOINT,token).then(err=>{
        if(err){
          return console.log(err.message);
        }else {
          console.log('Socket.io connected!')
          sockets.addListener(socketEvents);
          sockets.sendMessage('init','hello world!')
        }
      });
    }

    return Promise.resolve();
  }
  useEffect(()=>{
    checkAuth().catch(console.error)
    getExchangeRateAPI().then(r => dispatch(loadExchangeRates(r))).catch(console.error);;

  },[])

  return <AuthUserContext.Provider value={{ logged, setLogged, returnUrl, setReturnUrl, checkAuth }}>{children}</AuthUserContext.Provider>
}

export const useAuthUser = () => {
  return useContext(AuthUserContext)
}
