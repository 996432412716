
import React, {
    Dispatch,
    DragEventHandler,
    MutableRefObject,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
    DragEvent,
    MouseEventHandler,
    MouseEvent,
} from 'react';
import "./react-table.scss";
import "./react-table-striped.scss";
import {
    useReactTable,
    ColumnResizeMode,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    getSortedRowModel,
    SortingState,
    PaginationState, getPaginationRowModel, OnChangeFn, SortingFn,
} from '@tanstack/react-table';

import Paginator from "./paginator";
import ReactSelect from "../ReactSelect";
import {Row} from "@tanstack/table-core/src/types";
import {ReactTableProps} from "./react-table-props";
import ColumnContextMenu from "./column-context-menu";
import {useComponentVisible} from "./use-component-visible";
import ClickOutside from "./click-outside";

export * from "./select-column";

declare global {
    interface Array<T> {
        remove(elem: T): Array<T>;
        equals?:(arr:any[])=>boolean;
    }
}
if (!Array.prototype.equals){
    Array.prototype.equals = function (array:any[]) {
        // if the other array is a falsy value, return
        if (!array)
            return false;
        // if the argument is the same array, we can be sure the contents are same as well
        if(array === this)
            return true;
        // compare lengths - can save a lot of time
        if (this.length != array.length)
            return false;

        for (var i = 0, l=this.length; i < l; i++) {
            // Check if we have nested arrays
            if (this[i] instanceof Array && array[i] instanceof Array) {
                // recurse into the nested arrays
                if (!this[i].equals(array[i]))
                    return false;
            }
            else if (this[i] != array[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }
// Hide method from for-in loops
    Object.defineProperty(Array.prototype, "equals", {enumerable: false});
}

function useSkipper() {
    const shouldSkipRef = React.useRef(true)
    const shouldSkip = shouldSkipRef.current

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
        shouldSkipRef.current = false
    }, [])

    React.useEffect(() => {
        shouldSkipRef.current = true
    })

    return [shouldSkip, skip]
}

declare module '@tanstack/table-core' {
    interface SortingFns {
        sortByName: SortingFn<unknown>
    }
}

/*const column = columnHelper.data('key', {
    sortingFn: 'myCustomSorting',
})*/
function ReactTable(props:ReactTableProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [contextMenuPoint, setContextMenuPoint] = useState<{x:number,y:number}>({x:0,y:0})
    const [showContextMenu, setShowContextMenu] = useState<boolean>()
    const {
        data:_data,
        columns:_columns,
        style={},
        sortingColumns,
        onDrop,
        updateEditableCell,
        pageSize=15,
        onDragOver,
        colors={},
        onSelectedRowsChange,
        hiddenColumns={},

    } = props;
    const [sorting, setSorting] = React.useState(sortingColumns||[]);
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] = React.useState(hiddenColumns);
    const [columns] = React.useState(() => [..._columns,])

    const data = useMemo(()=>{
        const res = [];
        if(!_data){
            return []
        }
        let sliceData = _data;
        if(loading){
            setLoading(false);
            sliceData =  _data.slice(0,50);
        }/*else {
            sliceData = [..._data];
        }*/
        if(!colors||Object.values(colors).length===0){
            return sliceData;
        }
        for(const d of sliceData){
            res.push({
                ...d,
                backgroundColor:colors[d._id]
            })
        }
        return res;
    },[_data, colors, loading])
    useEffect(()=>{
        if(hiddenColumns&&JSON.stringify(hiddenColumns)!==JSON.stringify(columnVisibility)){
            setColumnVisibility(hiddenColumns)
        }
       // setColumnVisibility(hiddenColumns)
    },[hiddenColumns])
  //  console.log({data, colors})
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
 //   console.log({sorting})
    const table = useReactTable({
        data,
        columns,
       // columnResizeMode
        state: {
            sorting,
            rowSelection,
            columnVisibility,
        },
        initialState: {
            pagination: {
                pageSize,
            },
        },
        // @ts-ignore fixme
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,

        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        enableRowSelection: true,
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
     //   updateEditableCell,
     //   autoResetPage: false,
     //   autoResetFilters: false,
     //   autoResetSortBy: true,
     //   autoResetAll:false,
        autoResetPageIndex:false,
        sortingFns: {
            sortByName: (rowA: any, rowB: any, columnId: any): number =>{
                const a = rowA.getValue(columnId)?.name||'';
                const b = rowB.getValue(columnId)?.name||'';
                if(!a?.localeCompare){
                    return 0;
                }
                return a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'})
            }

        },
        meta: {
            updateData: async (rowIndex:number, columnId:number|string, value:any) => {
                // Skip page index reset until after next rerender
                //@ts-ignore fixme
                skipAutoResetPageIndex()
                return updateEditableCell&&updateEditableCell(rowIndex,columnId,value)
            },
        },
    });
    useEffect(() => {
        const sel = table.getSelectedRowModel().flatRows.map((row) => row.original);
       // console.log(sel);
     //   return;
        if(!onSelectedRowsChange){
            return;
        }
        onSelectedRowsChange(sel,)
    }, [rowSelection, table, onSelectedRowsChange])
    const onContextMenu = (e:  MouseEvent<HTMLTableSectionElement>)=>{
        e.preventDefault();
        setContextMenuPoint({
            x:e.pageX,
            y:e.pageY-50,
        })
       // setIsComponentVisible(true);
    }
/*    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(true);*/
    const contextMenuColumns = useMemo(()=>{
        const res = [];
        for(const columnId in hiddenColumns){
            res.push({
                columnId,
                visible:hiddenColumns[columnId]
            });
        }
        return res;
    },[hiddenColumns])
    return (
        <>
            <ClickOutside onClick={()=>setContextMenuPoint({x:0,y:0})}>
                <ColumnContextMenu {...contextMenuPoint} show={!!contextMenuPoint?.x} columns={contextMenuColumns} onCheck={props.onHideColumn}/>
            </ClickOutside>
            <div className="cl-card card-table" style={style}>

                <table className="table" >
                    <thead onContextMenu={onContextMenu}>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{width: `${header.getSize()}px`}}>
                                        {header.isPlaceholder ? null : (
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none d-flex align-items-center'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}

                                                {header.column.getIsSorted() ? {
                                                        asc: <div className="sort-icn c-up"></div>,
                                                        desc: <div className="sort-icn c-down"></div>,
                                                    }[header.column.getIsSorted() || 'asc']
                                                    : header.column.getCanSort() ?
                                                        <div className="sort-icn"></div> : null
                                                }
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table
                        .getRowModel()
                        .rows.map(row => {
                            const id = row.original?._id || row.original?.id || row.id;
                            const style = row.original.backgroundColor ? {backgroundColor: `#${row.original.backgroundColor}`} : {};
                            return (
                                <tr
                                    key={id}
                                    // style={style}
                                    onDragOver={onDragOver}
                                    onDrop={(e) => onDrop && onDrop(e, id)}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}
                                                style={{
                                                    width: cell.column.getSize(),
                                                    //   minWidth:cell.column.getMinSize(),
                                                }}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <br/>
                <Paginator
                    gotoPage={table.setPageIndex}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageCount={table.getPageCount()}
                    pageIndex={table.getState().pagination.pageIndex}
                    pageOptions={table.getPageOptions}
                    setPageSize={table.setPageSize}
                    previousPage={table.previousPage}
                    nextPage={table.nextPage}
                    pageSize={table.getState().pagination.pageSize}
                />

            </div>
        </>

    );
}

export default ReactTable;