const countries =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_COUNTRIES":
            return [
                ...action.data
            ];
        case "ADD_COUNTRY":
        case "UPDATE_COUNTRY":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_COUNTRY" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default countries;

export const loadCountries = data =>({type:'LOAD_COUNTRIES', data})
export const addCountry = data =>({type:'ADD_COUNTRY', data})
export const updateCountry = data =>({type:'UPDATE_COUNTRY', data})
export const deleteCountry = _id =>({type:'DELETE_COUNTRY', data:{_id}});
