import React, {ChangeEvent, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {EditableCellProps} from "./cell-props";

export function SwitchCell(props: EditableCellProps, options: any[]) {
    const {getValue, row: {index}, column: {id, getSize}, table} = props;
    const initialValue = getValue();
    const vall: any = initialValue ? initialValue[id] : null;
    const [value, setValue] = React.useState(vall)
    const [oldValue, setOldValue] = useState<any>({});
    const s = getSize();
    useEffect(() => {
        if (!vall) {
            return
        }
        setValue(initialValue[id]);
        if (!oldValue?._id) {
            setOldValue(initialValue)
        }
    }, [initialValue])
    const style = {
        control: (base: any) => ({
            ...base,
            border: 0,
            minWidth: 100,
            // This line disable the blue border
            boxShadow: 'none'
        }),
        indicatorSeparator: (styles: any) => ({display: 'none'})
    };
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        console.log({checked:value,old:oldValue[id]})
        if (value !== oldValue[id]) {
            table.options.meta?.updateData(initialValue?._id || index, id, value).then((r: any) => {
                /*if (!r) {
                    return setValue(oldValue[id]);
                }*/
                setValue(value);
                setOldValue({...oldValue, [id]: value});
            }).catch(console.error)
        }
    }

    return (
        <>
            <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                style={{cursor:'pointer'}}
                onChange={onChange}
                checked={!!value}
                {...props}
            />
            {/* <ReactSelect {...props} styles={style} onChange={onChange} initData={value} options={options}/>*/}
        </>
    );
}