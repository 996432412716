import { combineReducers } from 'redux'
import user from './user';
import users from './users';
import modems from "./modems";
import roles from "./roles";
import settings from "./settings";
import servers from "./servers";
import countries from "./countries";
import tariffs from "./tariffs";
import server_events from "./server-events";
import carriers from "./carriers";
import regions from "./regions";
import languages from "./languages";
import exchange_rates from "./exchange-rates";
import coupons from "./coupons";
import tariffsGb from "./tariff-gb-reducer";
import activeApplySettingsLogReducer from "./active-apply-settings-log-reducer";
import applySettingsLogReducer from "./apply-settings-log-reducer";
import bad_modems from "./bad-modems";
import telegramMessages from "./telegram-message-reducer";
import telegramUsers from "./telegram-user-reducer";
import paymentTransactions from "./payment-transactions";

export const rootReducer = combineReducers({
  user,
  users,
  modems,
  roles,
  settings,
  servers,
  countries,
  tariffs,
  server_events,
  carriers,
  regions,
  languages,
  exchange_rates,
  coupons,
  tariffsGb,
  activeApplySettingsLogReducer,
  applySettingsLogReducer,
  bad_modems,
  telegramMessages,
  telegramUsers,
  paymentTransactions
})
