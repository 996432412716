const carriers =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_CARRIERS":
            return [
                ...action.data
            ];
        default :
            return state
    }
}
export default carriers;

export const loadCarriers = data =>({type:'LOAD_CARRIERS', data})